import React, { useState } from 'react'
import Turnstile from "react-turnstile";
const initialFormData = {
    fullName: '',
    token: ''
}
export default function TurnstileFormExample() {
    const [formData, setFormData] = useState(initialFormData);
    // @ts-ignore
    const setValue = (k, v) => {
        // @ts-ignore
        setFormData((oldData) => (
            {
                ...oldData,
                [k]: v
            }
        ))
    }
    const handleSubmit = async () => {
        const endpoint = "/api/form-handler";
        const submitData = new FormData();
        const url = 'https://challenges.cloudflare.com/turnstile/v0/siteverify'

        for (const k in formData) {
            // @ts-ignore
            submitData.append(k, formData[k]);
        }
        try {
            const result = await fetch(url, {
                body: submitData,
                method: 'post'
            });
            const outcome = await result.json();
            console.log(outcome);
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <form>
            <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                onChange={(e) => setValue('fullName', e.target.value)}
            />
            <Turnstile
                sitekey={'0x4AAAAAAAOXI7K6e0stfV4G'}
                onVerify={(token) => setValue('token', token)}
            />
            <button type="button" onClick={handleSubmit}>Submit</button>
        </form>
    )
}