import React from 'react';


type CommunicationTextProps = {
    title: string
    content: string
    lineBreak?: boolean
};

const CommunicationText: React.FC<CommunicationTextProps> = (
    {
        title,
        content,
        lineBreak= true
    }) => {

    return (
        <React.Fragment>
            {/*<b><span style={{color: "#fff"}}>{title}</span></b>{content}{lineBreak ? <br/> : ''}*/}
            {title}{content}{lineBreak ? <br/> : ''}
        </React.Fragment>
    );
};

export default CommunicationText;