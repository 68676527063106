import React, {useEffect} from 'react';
import useWindowDimensions from "../utils/useWindowDimensions";
import {useTranslation} from "react-i18next";
import {useNotifiableUserSelector} from "../redux/store";
import {useNavigate} from "react-router";
import arrowfightLogo from '../asset/resource/ARROWFIGHT logo Delivery-13.svg'

export function ThankYouPage() {

    const successfulPostEmailAddress = useNotifiableUserSelector((state) => state.notifiableUser.successfulPostEmailAddress) as boolean;
    const errorPostEmailAddress = useNotifiableUserSelector((state) => state.notifiableUser.errorPostEmailAddress) as boolean;

    const {isResponsive} = useWindowDimensions();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({top: 0});
        if (!successfulPostEmailAddress) {
            navigate(`/`);
        }
    }, []);


    return (
        <div style={isResponsive ? {paddingTop: "20px"} : {paddingTop: "30px"}}>
            <div className="fullCenter">
                {successfulPostEmailAddress && !errorPostEmailAddress && <div className="fullCenter padding-top-xxl">
                    <div className="text-size-xxl justify-center">
                        <div className="padding-bottom-l text-size-xxl">{t("successPostMessageA")}</div>
                        <div className="padding-bottom-l">
                            <img src={arrowfightLogo} alt="arrowfight logo" className="arrowfight-logo"/>
                        </div>
                        <div>{t("successPostMessageB")}</div>
                    </div>
                </div>}
            </div>
        </div>
    );
}
