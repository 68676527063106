import {createAsyncThunk, createSlice, Reducer} from '@reduxjs/toolkit'
import axiosNotifiableUserService from '../utils/AxiosNotifiableUserService';
import {updateObject} from '../utils/utility';
import {NotifiableUserModel} from "../model/NotifiableUserModel";
import {AxiosResponse} from "axios/index";

const initialState = {
    successfulPostEmailAddress: false,
    errorPostEmailAddress: false,
    loading: false,
};

const postAddUserEmailRequest = (notifiableUserRequest: NotifiableUserModel) => {
    return axiosNotifiableUserService.post<unknown, AxiosResponse<NotifiableUserModel>>(
        `/api/notifiable`, JSON.stringify(notifiableUserRequest), {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        })
};

export const postAddUserEmailThunk = createAsyncThunk(
    'notifiableUser/post',
    async (obj: {notifiableUserRequest: NotifiableUserModel }, thunkAPI) => {
        // const updatedKeycloak = await validateOrRefreshToken();
        // return await postAddUserEmailRequest(obj.fightRequest, updatedKeycloak?.token);
        return await postAddUserEmailRequest(obj.notifiableUserRequest);
    }
)
export const notifiableUserEmailSlice = createSlice({
    name: 'notifiableUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            //postAddUserEmailThunk
            .addCase(postAddUserEmailThunk.pending, (state, action) => {
                return updateObject(state, {loading: true})
            })
            .addCase(postAddUserEmailThunk.fulfilled, (state, action) => {
                return updateObject(state, {
                    successfulPostEmailAddress: true,
                    errorPostEmailAddress: false,
                    loading: false
                });
            })
            .addCase(postAddUserEmailThunk.rejected, (state, action) => {
                return updateObject(state, {
                    errorPostEmailAddress: true,
                    loading: false
                })
            })
    }
})

export const {} = notifiableUserEmailSlice.actions;
export const notifiableUserEmailReducer: Reducer<typeof initialState> = notifiableUserEmailSlice.reducer;
