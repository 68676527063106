import React from 'react';
import {Route, Routes} from 'react-router';
import {LegalNotice} from "../content/LegalNotice";
import {Privacy} from "../content/Privacy";
import {LandingPage} from "../content/LandingPage";
import {PrivacyEN} from "../content/PrivacyEN";
import TurnstileFormExample from "../component/TurnstileFormExample";
import {ThankYouPage} from "../content/ThankYouPage";

export default function Routing() {

    function getRoutes() {
        return (
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/turnstile" element={<TurnstileFormExample/>}/>
                <Route path="/legalnotice" element={<LegalNotice/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/privacy-en" element={<PrivacyEN/>}/>
                <Route path="/thankyou" element={<ThankYouPage/>}/>
            </Routes>
        )
    }

    return (
        <React.Fragment>
            {/*<div className="cr cr-top cr-left cr-sticky cr-red">Beta</div>*/}
            {getRoutes()}
        </React.Fragment>
    );
}