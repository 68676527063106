import * as React from 'react';

import {AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import useWindowDimensions from "../utils/useWindowDimensions";
import {Trans, useTranslation} from "react-i18next";
// @ts-ignore
import Cookies from "js-cookie";
import {Link} from "react-router-dom";

const Footer = () => {

    const {isResponsive} = useWindowDimensions();

    const {t, i18n} = useTranslation();
    const currentLocale = Cookies.get("i18next");

    function getPrivacyLinkTo() {
        if (!currentLocale) {
            return '/privacy';
        }
        if (currentLocale === "de") {
            return '/privacy';
        } else {
            return '/privacy-en';
        }
    }

    return (
        <>
            {isResponsive
                ? <AppBar position='bottom' className="background-grey font-arrowhacks text-size-s">
                    <div className="margin-top-l">
                        <div className="padding-bottom-l">
                            <AppBarSection>
                                <div>
                                    <div className="padding-bottom-m">
                                        <Trans i18nKey="name"><span style={{color: "#E30C17"}}/>
                                        </Trans>&nbsp;GmbH & Co. KG
                                    </div>
                                    <div className="padding-bottom-m">Julius-Hatry-Straße 1</div>
                                    <div className="padding-bottom-m">68163 Mannheim</div>
                                </div>
                            </AppBarSection>
                        </div>
                        <div className="padding-bottom-l">
                            <AppBarSection>
                                <div>
                                    <div className="padding-bottom-m">
                                        <Link to='/legalnotice'
                                              className="text-color-white">{t("landingPage.footer.impressum_link")}</Link>
                                    </div>
                                    <div>
                                        <Link to={getPrivacyLinkTo()}
                                              className="text-color-white">{t("landingPage.footer.privacy_link")}</Link>
                                    </div>
                                </div>
                            </AppBarSection>
                        </div>
                        <AppBarSection>
                            <div>
                                <div className="padding-bottom-m">tel:&nbsp;
                                    <a href={`tel:${t('tel')}`} className="text-color-white">{t("tel")}</a>
                                </div>
                                <div className="padding-bottom-m">mail:&nbsp;
                                    <Trans i18nKey="email">
                                        <span style={{color: "#E30C17"}}/>
                                    </Trans>
                                </div>
                            </div>
                        </AppBarSection>
                    </div>
                </AppBar>
                : <AppBar position='bottom' className="background-grey font-arrowhacks text-size-s">
                    <AppBarSpacer/>
                    <AppBarSection>
                        <div>
                            <div className="padding-bottom-m">
                                <Trans i18nKey="name"><span style={{color: "#E30C17"}}/>
                                </Trans>&nbsp;GmbH & Co. KG
                            </div>
                            <div className="padding-bottom-m">Julius-Hatry-Straße 1</div>
                            <div className="padding-bottom-m">68163 Mannheim</div>
                        </div>
                    </AppBarSection>
                    <AppBarSpacer/>
                    <AppBarSection>
                        <div>
                            <div className="padding-bottom-m">
                                <Link to='/legalnotice'
                                      className="text-color-white">{t("landingPage.footer.impressum_link")}</Link>
                            </div>
                            <div>
                                <Link to={getPrivacyLinkTo()}
                                      className="text-color-white">{t("landingPage.footer.privacy_link")}</Link>
                            </div>
                        </div>
                    </AppBarSection>
                    <AppBarSpacer/>
                    <AppBarSection>
                        <div>
                            <div className="padding-bottom-m">tel:&nbsp;{t("tel")}</div>
                            <div className="padding-bottom-m">mail:&nbsp;
                                <Trans i18nKey="email">
                                    <span style={{color: "#E30C17"}}/>
                                </Trans>
                            </div>
                            <div className="padding-bottom-m">
                            </div>
                        </div>
                    </AppBarSection>
                    <AppBarSpacer/>
                </AppBar>
            }
        </>
    )
}

export default Footer;