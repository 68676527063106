import './Spinner.scss';

export default function spinner() {
    return (
        <div className="Loader">
            <div className="Inner one"></div>
            <div className="Inner two"></div>
            <div className="Inner three"></div>
        </div>
    )
}

