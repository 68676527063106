import React, {useEffect} from 'react';
import CommunicationText from "../component/CommunicationText";
import {Disclaimer} from "./Disclaimer";
import {useTranslation} from "react-i18next";

export function LegalNotice() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0});
    }, []);

    return (
        <div className="font-arrowhacks">
            <div className="padding-bottom-l">
                <div>
                    <h1>{t("legalNotice.title")}</h1>
                    <h2>{t("legalNotice.tmg5")}</h2>
                    <h3>{t("legalNotice.serviceInfo")}</h3>
                    <p>arrowhacks GmbH &amp; Co. KG<br/>
                        Julius-Hatry-Straße 1<br/>
                        68163 Mannheim</p>
                    <p>{t("legalNotice.commercialRegister")}: HRA 711422<br/>
                        {t("legalNotice.registrationCourt")}: Amtsgericht Mannheim</p>

                    <p><strong>Vertreten durch:</strong><br/>
                        arrowhacks Verwaltungs GmbH</p>

                    <p>{t("legalNotice.representedBy")}:<br/>
                        Enis Brulic</p>

                    <p>{t("legalNotice.commercialRegister")}: HRB 747404<br/>
                        {t("legalNotice.registrationCourt")}: Amtsgericht Mannheim</p>
                </div>
                <div>
                    <h2>{t("legalNotice.contact")}</h2>
                    <p>
                        <CommunicationText title="Tel: " content="+49 621 5869 8960"/>
                        <CommunicationText title="Fax: " content="+49 621 5869 8969"/>
                        <CommunicationText title="E-Mail: " content="contact@arrowhacks.com"/>
                        <div>
                            Web: <a href="https://arrowhacks.com" target="_blank" rel="noopener noreferrer">https://arrowhacks.com</a>
                        </div>
                    </p>
                </div>
                <div>
                    <h2>{t("legalNotice.vatIdTitle")}</h2>
                    <p>{t("legalNotice.vatIdContent")}:<br/>
                        DE362402425</p>
                </div>
                <div>
                    <h2>{t("legalNotice.personResponsible")}</h2>
                    <p>Enis Brulic</p>

                    <h2>{t("legalNotice.EUDisputeResolution")}</h2>
                    <p>{t("legalNotice.EUKommission")} : <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
                                                            rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/> {t("legalNotice.EUKommissionEmailInfo")}
                    </p>

                    <h2>{t("legalNotice.disputeResolutionProceedingsTitle")}</h2>
                    <p>{t("legalNotice.disputeResolutionProceedingsContent")}</p>
                </div>
            </div>
            <div className="padding-bottom-l">
                <Disclaimer/>
            </div>
            {/*<div className="padding-bottom-l">*/}
            {/*<Trademarks/>*/}
            {/*</div>*/}
        </div>

    );
}
