import React from 'react';
import {useTranslation} from "react-i18next";

export function Disclaimer() {

    const {t, i18n} = useTranslation();

    // https://www.disclaimer.de/disclaimer.htm?farbe=E5E5FF/000088/1111FF/1111FF
    return (
        <div>
            <h1>{t("disclaimer.title")}</h1>
            <h2>{t("disclaimer.contentTitle")}</h2>
            <p>{t("disclaimer.content")}</p>
            <h2>{t("disclaimer.referralsTitle")}</h2>
            <p>{t("disclaimer.referrals")}</p>
            <h2>{t("disclaimer.copyrightTitle")}</h2>
            <p>{t("disclaimer.copyright")}</p>
            <h2>{t("disclaimer.privacyPolicyTitle")}</h2>
            <p>{t("disclaimer.privacyPolicy")}</p>
            <h2>{t("disclaimer.legalValidityTitle")}</h2>
            <p>{t("disclaimer.legalValidity")}</p>

        </div>

    );
}
